<template>
  <article class="google-review">
    <div class="header row full-width static">
      <div class="info row static">
        <div class="mark row">
          <img v-if="source==='google'" alt="Star" v-for="_ in stars" width="auto" height="14" src="/svg/star.svg">
          <img style="width: 86px;" v-else alt="Star" :src="`/images/reviews/trustpilot/${stars}.png`">
        </div>
        <div class="name">{{ client }}</div>
      </div>
      <img class="logo"
           :src="source==='google' ? '/images/reviews/Google-Logo.png' : '/images/reviews/trustpilot-logo.png'"></div>
    <div class="title">{{ title }}</div>
    <div class="text">{{ text }}</div>
  </article>
</template>

<script>
export default {
  props: {
    stars: {
      type: Number,
      default: 5
    },
    client: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    source: {
      type: String,
      default: 'google'
    }
  }
}
</script>

<style scoped lang="scss">
.google-review {
  box-shadow: 0 3px 6px rgba(183, 182, 182, 0.16);
  border: 1px solid $light-grey;
  height: 179px;
  padding: 25px;
  background: white;
  position: relative;

  @include smd {
    height: 242px;
    width: 96%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .header {
    align-items: flex-start;

    .info {
      gap: 20px;

      .mark {
        align-items: center;
        background: transparent;
        color: $yellow;
        font-size: 12px;
        font-weight: 400;
        padding: 0;

        .score {
          color: $light-black;
          margin-right: 8px;
          font-weight: 500;
        }
      }
    }

    img.logo {
      margin-left: auto;
      height: 18px;
    }

    .image {
      img {
        max-width: 40px;
      }
    }
  }

  .name {
    color: $super-light-black;
    font-size: 12px;

    @include smd {
      font-size: 12px;
      position: absolute;
      bottom: 15px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    margin-top: 15px;

    @include smd {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  .text {
    color: $black;
    font-size: 12px;
    font-weight: 400;
    margin-top: 17px;

    @include smd {
      height: 75px;
      margin-top: 12px;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
